import { connect } from 'react-redux';
import { getNoOfFavourites, getCurrentUser } from 'common/helpers/select';
import Header from './Header';

const mapStateToProps = state => ({
  user: getCurrentUser(state),
  noOfFavourites: getNoOfFavourites(state),
  navBarDisplay: state.ui.navBarDisplay,
  searchBarDisplay: state.ui.searchBarDisplay
});

export default connect(mapStateToProps, null)(Header);