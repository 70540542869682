import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'next/router';
import { withStyles } from '@material-ui/core/styles';
import Router from 'next/router';
import Image from 'next/image';

import { identity, isNil, path, prop } from 'ramda';

import * as SearchSuggestionSelectors from 'common/store/selector/searchSuggestion';
import {
  startSearchSuggestion,
  clearAndHideSearchResults,
} from 'common/store/actions';
import { Autocomplete } from '@material-ui/lab';
import {
  TextField,
  InputAdornment,
  IconButton,
  Hidden,
  ClickAwayListener,
  Fade,
  Paper,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { debounce } from 'throttle-debounce';
import Link from 'next/link';
import memoizeOne from 'memoize-one';

const styles = (theme) => ({
  searchBar: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 540,
    },
    '& .MuiTextField-root': {
      borderRadius: '8px',
    },
  },
  searchBarMobile: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0',
    boxShadow: '0 5px 15px -11px #7c7c7c',
    display: 'flex',
    marginTop: 0,
    left: '0',
    padding: '8px 10px',
    position: 'absolute',
    width: '100%',
    zIndex: '1000',
    top: 0,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& .MuiTextField-root': {
      borderRadius: '8px',
    },
  },
  mobileFilters: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  listItemText: {
    fontWeight: 700,
    fontSize: '16px !important',
    lineHeight: '18px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      lineHeight: '16px !important',
    },
  },
  searchIcon: {
    color: '#fff',
    padding: 5,
  },
  searchToggleIcon: {
    color: '#fff',
  },
  closeIconInMobile: {
    color: '#fff',
    marginRight: 5,
    padding: 0,
  },
  searchTextField: {
    background: 'rgba(255, 255, 255, 0.15)',
    color: '#fff',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.25)',
    },
  },
  inputRoot: {
    color: '#fff',
  },
  inputNotchedOutline: {
    border: 'none',
  },
  autocompleteRoot: {
    width: '100%',
  },
  autocompleteNoOptions: {
    display: 'none',
  },
  autocompleteLoading: {
    display: 'none',
  },
  product: {
    alignItems: 'center',
    display: 'flex',
    margin: 5,
    width: '100%',
    overflow: 'hidden',
  },
  productImageWrapper: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  productImage: {
    width: '100%',
    borderRadius: '50%',
  },
  productInfo: {
    flexGrow: 1,
    flexBasis: 1,
    marginLeft: 15,
    overflow: 'hidden',
  },
  productTitle: {
    fontWeight: 700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: '0 5px 0',
  },
  productStore: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: '0 5px 0',
    color: 'rgb(66, 66, 66)',
  },
  isSearching: {
    width: '16px',
    height: '16px',
    display: 'inline-block',
    border: '8px solid rgb(205, 209, 212)',
    margin: '4px',
    animation: ' $searchAnimation 0.85s linear 0s infinite normal none running',
    opacity: '0.7',
  },
  '@keyframes searchAnimation': {
    '0%': {
      borderColor:
        'rgb(205, 209, 212) rgb(232, 233, 234) rgb(232, 233, 234) rgb(205, 209, 212)',
    },
    '35%': {
      borderColor:
        'rgb(205, 209, 212) rgb(205, 209, 212) rgb(232, 233, 234) rgb(232, 233, 234)',
      borderRadius: '50%',
    },
    '50%': {
      borderColor:
        'rgb(232, 233, 234) rgb(205, 209, 212) rgb(205, 209, 212) rgb(232, 233, 234)',
      borderRadius: '50%',
    },

    '55%': {
      borderColor:
        'rgb(232, 233, 234) rgb(232, 233, 234) rgb(205, 209, 212) rgb(205, 209, 212)',
      borderRadius: '50%',
    },
    '100%': {
      borderColor:
        'rgb(205, 209, 212) rgb(232, 233, 234) rgb(232, 233, 234) rgb(205, 209, 212)',
      transform: 'rotate(360deg)',
    },
  },
});

class SearchBarPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      isLoading: false,
      isShowSearchMobile: false,
    };

    this.autocomplete = {
      classes: {
        root: props.classes.autocompleteRoot,
        noOptions: props.classes.autocompleteNoOptions,
        loading: props.classes.autocompleteLoading,
      },
    };

    this.handleSearchSuggestion = debounce(300, this.handleSearchSuggestion);
  }

  routeChangeStart = (url) => {
    if (url.startsWith('/search')) {
      this.setState({
        isLoading: true,
      });
    }
  };

  routeChangeComplete = () => {
    this.setState({
      isLoading: false,
    });
  };

  routeChangeError = () => {
    this.setState({
      isLoading: false,
    });
  };

  componentDidMount() {
    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeComplete);
    Router.events.on('routeChangeError', this.routeChangeError);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.routeChangeStart);
    Router.events.off('routeChangeComplete', this.routeChangeComplete);
    Router.events.off('routeChangeError', this.routeChangeError);
  }

  getProducts = memoizeOne((searchProducts) => searchProducts.slice(0, 8));

  handleCloseSearchInMobile = () => {
    this.setState({
      isShowSearchMobile: false,
    });
  };

  handleToggleSearchInMobile = () => {
    this.setState((prevState) => ({
      isShowSearchMobile: !prevState.isShowSearchMobile,
    }));
  };

  handleInputChange = (event) => {
    const value = path(['target', 'value'], event);

    if (!isNil(value)) {
      this.setState({
        searchTerm: event.target.value,
      });
      this.handleSearchSuggestion(event.target.value);
    }
  };

  handleSearchSuggestion = (searchTerm) => {
    if (this.props.searchQuery !== searchTerm) {
      this.props.startSearchSuggestion(searchTerm);
    }
  };

  navigateToSearchPage = () => {
    this.props.router.push(`/search?q=${this.state.searchTerm}&type=product`);
  };

  renderAutocomplete = () => {
    const { classes, searchProducts, searchSuggestionsLoading } = this.props;
    const products = this.getProducts(searchProducts);

    return (
      <Autocomplete
        classes={this.autocomplete.classes}
        options={products}
        filterOptions={identity}
        inputValue={this.state.searchTerm}
        onInputChange={this.handleInputChange}
        loading={searchSuggestionsLoading}
        disabled={this.state.isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for products..."
            variant="outlined"
            size="small"
            className={classes.searchTextField}
            fullWidth
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.inputRoot,
                notchedOutline: classes.inputNotchedOutline,
              },
              startAdornment: (
                <React.Fragment>
                  <InputAdornment position="start">
                    <IconButton
                      disabled={
                        searchSuggestionsLoading || this.state.isLoading
                      }
                      type="submit"
                      className={classes.searchIcon}
                      onClick={this.navigateToSearchPage}
                    >
                      <Choose>
                        <When
                          condition={
                            searchSuggestionsLoading || this.state.isLoading
                          }
                        >
                          <i className={classes.isSearching} />
                        </When>
                        <Otherwise>
                          <SearchIcon />
                        </Otherwise>
                      </Choose>
                    </IconButton>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: null,
            }}
          />
        )}
        getOptionLabel={prop('title')}
        renderOption={(option) => (
          <Link href={`/products/${option.id}`} key={option.id} legacyBehavior>
            <div className={classes.product}>
              <div className={classes.productImageWrapper}>
                <Image
                  src={option.thumbnail_image_url}
                  className={classes.productImage}
                  width={40}
                  height={40}
                />
              </div>

              <div className={classes.productInfo}>
                <p className={classes.productTitle}>{option.title}</p>
                <p className={classes.productStore}>{option.store}</p>
              </div>
            </div>
          </Link>
        )}
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.searchBar}>
        <Hidden xsDown>{this.renderAutocomplete()}</Hidden>

        <Hidden smUp>
          <ClickAwayListener onClickAway={this.handleCloseSearchInMobile}>
            <div>
              <IconButton
                className={classes.searchToggleIcon}
                onClick={this.handleToggleSearchInMobile}
              >
                <SearchIcon />
              </IconButton>

              <Fade in={this.state.isShowSearchMobile}>
                <Paper square elevation={4} className={classes.searchBarMobile}>
                  {this.renderAutocomplete()}

                  <IconButton
                    onClick={this.handleCloseSearchInMobile}
                    className={classes.closeIconInMobile}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Paper>
              </Fade>
            </div>
          </ClickAwayListener>
        </Hidden>
      </div>
    );
  }
}

SearchBarPanel.defaultProps = {
  searchLoading: false,
  disabled: false,
  handleBlur: () => null,
  handleFocus: () => null,
  handleChange: () => null,
  handleShowAll: () => null,
  startSearchSuggestion: () => null,
  searchSuggestionsLoading: false,
  searchProducts: [],
  searchQuery: null,
};

SearchBarPanel.propTypes = {
  searchLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleChange: PropTypes.func,
  handleShowAll: PropTypes.func,
  startSearchSuggestion: PropTypes.func,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  searchSuggestionsLoading: PropTypes.bool,
  searchProducts: PropTypes.array,
  searchQuery: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  searchSuggestions: ({ searchSuggestion: { search } }) => search,
  searchQuery: ({ searchSuggestion: { query } }) => query,
  searchProducts: SearchSuggestionSelectors.selectSearchSuggestionProducts,
  searchSuggestionsLoading: ({ searchSuggestion: { loading } }) => loading,
  searchSuggestionsHidden: ({ searchSuggestion: { hidden } }) => hidden,
});

const mapDispatchToProps = (dispatch) => {
  return {
    startSearchSuggestion: (keyword) =>
      dispatch(startSearchSuggestion(keyword)),
    clearAndHideSearchResults: () => dispatch(clearAndHideSearchResults()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(SearchBarPanel);
