import { createSelector } from 'reselect';
import { selectProductEntities } from './entities';

export const selectSearchSuggestion = state => state.searchSuggestion;

export const selectSearchSuggestionIds = createSelector(
  selectSearchSuggestion,
  searchSuggestionState => searchSuggestionState.searchIds
);

export const selectSearchSuggestionProducts = createSelector(
  selectSearchSuggestionIds,
  selectProductEntities,
  (searchIds, productEntities) => searchIds.map(id => productEntities[id])
);
