import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Grid,
  Hidden,
  IconButton,
  Tabs,
  Tab,
  Toolbar,
} from '@material-ui/core';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import HistoryIcon from '@material-ui/icons/History';

import HeaderLogo from './HeaderLogo';
import SearchBarPanel from 'common/components/Panels/SearchBar';

const HeaderUserbox = dynamic(() => import('./HeaderUserbox'));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledAvatar = withStyles((theme) => ({
  root: {
    width: '26px !important',
    height: '26px !important',
    display: 'flex !important',
    fontSize: '0.875rem !important',
    fontWeight: '500 !important',
    overflow: 'hidden !important',
    position: 'relative !important',
    alignItems: 'center !important',
    flexShrink: '0 !important',
    borderRadius: '50% !important',
    justifyContent: 'center !important',
    backgroundColor: 'white !important',
    color: 'green !important',
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  toolBar: {
    minHeight: '58px !important',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(-1),
  },
  link: {
    textDecoration: 'none',
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  customBadge: {
    backgroundColor: '#cd6978',
  },
  login: {
    padding: '8px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  customHoverFocus: {
    '&:hover, &.Mui-focusVisible': { backgroundColor: 'transparent' },
  },
  userName: {
    color: 'white !important',
    display: 'block !important',
    textAlign: 'inherit !important',
    marginLeft: '10px !important',
    fontSize: '0.880rem !important',
    fontWeight: '600 !important',
    '&:hover': {
      fontWeight: '600 !important',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchBar: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    height: '30px',
    lineHeight: '30px',
    borderRight: '1px solid #cccccc82',
  },
  heartIcon: {
    padding: '0 20px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  tabs: {
    overflow: 'visible !important',
    backgroundColor: '#fff',
    borderBottom: '1px solid #cacaca',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
    },
    '& .MuiTabs-scroller': {
      overflow: 'visible !important',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-centered': {
        justifyContent: 'space-evenly',
      },
    },
  },
  tabPanel: {
    color: '#000000',
    minHeight: '48px',
    '& .MuiTab-wrapper': {
      display: 'flex !important',
      flexDirection: 'row',
      textTransform: 'capitalize',
      '& .MuiSvgIcon-root': {
        marginBottom: 0,
        marginRight: 12,
      },
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        top: '1px',
        right: '12px',
      },
    },
  },
  discoverBtn: {
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '21px',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('md')]: {
      width: 120,
    },
  },
  badge: {
    marginBottom: '0px !important',
    '& .MuiBadge-badge': {
      fontSize: '0.6rem',
      padding: '0px 4px',
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const {
    user,
    searchBarDisplay,
    noOfFavourites,
    tabDisplay,
    orderType,
    handleTabsChange,
    subscriptionTabDisplay,
  } = props;

  let authLinks;
  if (user && !user.is_anonymous) {
    var firstName = user.name.split(' ')[0];
    var initials = user.name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();

    authLinks = (
      <Link href="/account" legacyBehavior>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
          disableRipple
          className={classes.customHoverFocus}
        >
          <StyledAvatar>{initials}</StyledAvatar>
          <span className={classes.userName}>{firstName}</span>
        </IconButton>
      </Link>
    );
  } else {
    authLinks = (
      <Link href="/auth/login" legacyBehavior>
        <Button color="inherit" className={classes.login}>
          Log in
        </Button>
      </Link>
    );
  }

  return (
    <Fragment>
      <AppBar
        color="primary"
        position="sticky"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolBar}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={2} sm={2} md={2} lg={1}>
              <HeaderLogo />
            </Grid>

            <If condition={!searchBarDisplay}>
              <Hidden smDown>
                <Grid item xs="auto" sm={6} md={6} className={classes.discover}>
                  {/* <Link route="discover">
                    <Button color="inherit" className={classes.discoverBtn}>
                      Discover
                    </Button>
                  </Link> */}
                </Grid>
              </Hidden>
            </If>

            <If condition={searchBarDisplay}>
              <Grid item xs="auto" sm={6} md={6} className={classes.searchBar}>
                <SearchBarPanel />
              </Grid>
            </If>

            <Grid item xs="auto" sm="auto" md={4}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Hidden smDown>
                  <Grid item>
                    <Link href="/favorites" legacyBehavior>
                      <IconButton
                        aria-label="show new notifications"
                        color="inherit"
                      >
                        <Badge
                          badgeContent={noOfFavourites}
                          classes={{ badge: classes.customBadge }}
                        >
                          <FavoriteBorderIcon />
                        </Badge>
                      </IconButton>
                    </Link>
                  </Grid>
                </Hidden>

                <Hidden smDown>
                  <Grid item>
                    <div className={classes.divider}></div>
                  </Grid>
                </Hidden>

                <Hidden smDown>
                  <Grid item>{authLinks}</Grid>
                </Hidden>

                <Grid item>
                  <HeaderUserbox />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {tabDisplay && (
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="primary"
          position="static"
          elevation={0}
        >
          <Tabs
            value={orderType}
            textColor="inherit"
            className={classes.tabs}
            onChange={handleTabsChange}
            centered
          >
            <Tab
              label="My Favorites"
              icon={<FavoriteIcon fontSize="small" />}
              {...a11yProps(0)}
              className={classes.tabPanel}
              disableRipple={true}
            />
            <Tab
              label="Alerts"
              icon={
                <Badge
                  badgeContent={999}
                  color="primary"
                  className={classes.badge}
                >
                  <NotificationsIcon fontSize="small" max={99} />
                </Badge>
              }
              {...a11yProps(0)}
              className={classes.tabPanel}
              disableRipple={true}
            />
            <Tab
              label="Discarded"
              icon={<DeleteIcon fontSize="small" />}
              {...a11yProps(0)}
              className={classes.tabPanel}
              disableRipple={true}
            />
          </Tabs>
        </AppBar>
      )}
      {subscriptionTabDisplay && (
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="primary"
          position="static"
          elevation={0}
        >
          <Tabs
            value={orderType}
            textColor="inherit"
            className={classes.tabs}
            onChange={handleTabsChange}
            centered
          >
            <Tab
              label="Subscription"
              icon={<CardMembershipIcon fontSize="small" />}
              {...a11yProps(0)}
              className={classes.tabPanel}
              disableRipple={true}
            />
            <Tab
              label="Billing History"
              icon={<HistoryIcon fontSize="small" />}
              {...a11yProps(0)}
              className={classes.tabPanel}
              disableRipple={true}
            />
          </Tabs>
        </AppBar>
      )}
    </Fragment>
  );
}

Header.defaultProps = {
  user: {},
  tabDisplay: false,
  orderType: null,
  handleTabsChange: () => null,
  subscriptionTabDisplay: false,
};

Header.propTypes = {
  user: PropTypes.object,
  searchBarDisplay: PropTypes.any.isRequired,
  noOfFavourites: PropTypes.any.isRequired,
  tabDisplay: PropTypes.bool,
  orderType: PropTypes.any,
  handleTabsChange: PropTypes.func,
  subscriptionTabDisplay: PropTypes.bool,
};
