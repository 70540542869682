import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { withStyles } from '@material-ui/core/styles';
import { GlobalStyle } from './nprogressStyles';
import Header from './Header';

import { ASSETS_ENDPOINT } from 'config';

const ForceUserSubscribe = dynamic(() =>
  import('common/components/Dialogs/ForceUserSubscribeDialog'),
);

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexBasis: 1,
  },
});

const defaultTitle = 'Dropseeker';
const defaultTitleTagLine = 'Curated products for your store, all in one place';

class Page extends Component {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    tabDisplay: false,
    orderType: 0,
  };

  render() {
    const {
      classes,
      children,
      title,
      tabDisplay,
      orderType,
      handleTabsChange,
      subscriptionTabDisplay,
    } = this.props;

    return (
      <div
        className={clsx({
          [classes.root]: true,
        })}
      >
        <Head>
          <title>
            {title
              ? `${title} | ${defaultTitle}`
              : `${defaultTitle} | ${defaultTitleTagLine}`}
          </title>
          <link
            rel="icon"
            type="image/png"
            href={ASSETS_ENDPOINT + '/dropseeker-favicon.png'}
          />
          <link
            rel="shortcut icon"
            type="image/svg+xml"
            href={ASSETS_ENDPOINT + '/dropseeker-favicon.svg'}
          />
        </Head>

        <Header
          tabDisplay={tabDisplay}
          subscriptionTabDisplay={subscriptionTabDisplay}
          orderType={orderType}
          handleTabsChange={handleTabsChange}
        />
        <GlobalStyle />

        <main className={classes.main}>{children}</main>
        <ForceUserSubscribe />
      </div>
    );
  }
}

Page.defaultProps = {
  children: null,
  title: '',
  orderType: null,
  tabDisplay: false,
  transparentNav: false,
  subscriptionTabDisplay: false,
  handleTabsChange: () => null,
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  orderType: PropTypes.number,
  tabDisplay: PropTypes.bool,
  transparentNav: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  subscriptionTabDisplay: PropTypes.bool,
  handleTabsChange: PropTypes.func,
};

export default withStyles(styles)(Page);
