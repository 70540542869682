import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from 'next/link';
import Image from 'next/image';
import dropseekerLogoImg from 'public/static/images/dropseeker-logo.png'

const styles = (theme) => ({
  image: {
    marginTop: '5px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      height: 22,
      width: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      height: 19,
      width: 'auto'
    },
  },
});

const Logo = (props) => {
  const { classes } = props;

  return (
    <Link href="/" legacyBehavior>
      <Image
        src={dropseekerLogoImg}
        className={classes.image}
        alt="Logo"
      />
    </Link>
  );
};

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Logo);
