import { createSelector } from 'reselect';
import { __, defaultTo, map, pipe, prop } from 'ramda';

export const selectEntities = (state) => state.entities;

export const selectProductEntities = createSelector(
  selectEntities,
  (entities) => entities.products,
);

export const selectCollectionEntities = createSelector(
  selectEntities,
  (entities) => entities.collections,
);

export const selectSavedProductsEntities = createSelector(
  selectEntities,
  (entities) => entities.savedProducts,
);

export const selectDiscardedProductsEntities = createSelector(
  selectEntities,
  (entities) => entities.discardedProducts,
);

export const getSavedProducts = createSelector(
  selectProductEntities,
  selectSavedProductsEntities,
  (products, savedProducts) => {
    return pipe(defaultTo([]), map(prop(__, products)))(savedProducts);
  },
);

export const getDiscardedProducts = createSelector(
  selectProductEntities,
  selectDiscardedProductsEntities,
  (products, discardedProducts) => {
    return pipe(defaultTo([]), map(prop(__, products)))(discardedProducts);
  },
);
