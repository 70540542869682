import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #3F7BF3;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }
  
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #7266ba, 0 0 5px #7266ba;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }
`;
